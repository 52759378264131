import TextArea from "@/components/common/forms/textarea";
import { Checkbox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ProgressButtons } from "./progressButtons";
import { PollQuestion } from "@/types/poll";
import axios from "axios";
import { usePage } from "@inertiajs/react";
import toast from "react-hot-toast";

interface FollowUpInterface {
    previous: () => void;
    next: () => void;
    question: PollQuestion;
    setProgress: (e: number) => void;
    mock?: boolean;
}
export default function FollowUp({
    previous,
    next,
    setProgress,
    question,
    mock = false,
}: FollowUpInterface) {
    const { csrfToken } = usePage().props;

    const intl = useIntl();
    const [answer, setAnswer] = useState("");
    const [consent, setConsent] = useState(false);
    const [error, setError] = useState(false);

    const save = async (callback) => {
        if (mock) {
            callback();
        } else {
            try {
                await axios.post(
                    route("polls.user.vote", question.id),
                    { question_data: [answer] },
                    {
                        headers: {
                            "X-Csrf-Token": csrfToken,
                        },
                    },
                );
                callback();
            } catch (e) {
                console.error(e);
                toast.error("Saving failed, please try again");
            }
        }
    };

    useEffect(() => {
        if (answer) {
            setProgress(1);
        } else {
            setProgress(0.5);
        }
    }, [answer]);
    const characterLimitReached =
        answer.length > (question.maximum_characters || 100);

    return (
        <>
            <div>
                <div className="flex flex-col">
                    <span className="text-xl pb-1">{question.question}</span>
                    <span className="pb-1">{question.description}</span>
                </div>

                <TextArea
                    className={`w-full resize-none ${characterLimitReached ? " !border-red-600" : ""}`}
                    rows={7}
                    placeholder={intl.formatMessage({
                        id: "Write your opinion here",
                    })}
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                />
                <div
                    className={`text-xs text-gray-600 flex justify-between flex-row-reverse ${characterLimitReached ? "text-red-600" : ""}`}
                >
                    <span>
                        {answer.length}/{question.maximum_characters}
                    </span>
                    {characterLimitReached && (
                        <span>
                            <FormattedMessage id="Max characters reached" />
                        </span>
                    )}
                </div>
                <div className="flex gap-x-2">
                    <Checkbox
                        checked={consent}
                        onChange={(e) => {
                            setConsent(e);
                            if (e) {
                                setError(false);
                            }
                        }}
                        className="group block size-5 rounded border bg-white data-[checked]:bg-voice"
                    >
                        <CheckIcon className="text-white opacity-0 group-data-[checked]:opacity-100" />
                    </Checkbox>
                    <span className="text-sm">
                        <FormattedMessage id="I authorize the use of my answers to VOICE" />
                    </span>
                </div>
                {error && (
                    <span className="text-xs text-red-600">
                        <FormattedMessage id="Please agree to authorisation before submitting" />
                    </span>
                )}
            </div>
            <ProgressButtons
                previousLabel="Skip"
                previous={previous}
                next={() => {
                    if (characterLimitReached) {
                        /* Do nothing */
                    } else if (consent) {
                        save(() => {
                            setProgress(1);
                            next();
                        });
                    } else if (answer === "") {
                        next();
                    } else {
                        setError(true);
                    }
                }}
                nextLabel="Submit"
            />
        </>
    );
}
